import "bootstrap/dist/js/bootstrap.min.js";
import "@popperjs/core/dist/umd/popper.min.js";
// import "path/to/prism.css"

// const onClientEntry = () => {
//     const link = document.createElement("link");
//     link.href = "https://assets.calendly.com/assets/external/widget.css";
//     link.rel = "stylesheet";
//     document.head.appendChild(link);
//   };
//   export default onClientEntry;

// gatsby-browser.js

export const onClientEntry = () => {
    document.addEventListener("DOMContentLoaded", function () {
      function handleScroll() {
        document.body.classList.add("scroll-visible");
  
        clearTimeout(scrollTimeout);
        scrollTimeout = setTimeout(() => {
          document.body.classList.remove("scroll-visible");
        }, 2000);
      }
  
      let scrollTimeout;
      window.addEventListener("scroll", handleScroll);
    });
  };
  